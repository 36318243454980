@use 'sass:math';

@import '../../styles/utils';

.landing-section {
    position: relative;
}

.landing-section--white {
    background-color: $neutral-0;
}

.landing-section--grey {
    background-color: $neutral-20;
}

.landing-section--blue {
    background-color: $blue1;
}

.landing-section--purple {
    background-color: $accent-2-70;
}

.landing-section--debug {
    $grid-debug-width: math.div(100, $grid-items) * 1%;

    &::after {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $z-grid-debug;

        display: block;

        max-width: 100%;
        height: 100%;
        margin: 0 auto;

        background: repeating-linear-gradient(
            90deg,
            rgba(238, 103, 35, 0.15) 0,
            rgba(238, 103, 35, 0.15) $grid-gap,
            rgba(238, 103, 35, 0.3) $grid-gap,
            rgba(238, 103, 35, 0.3) calc(#{$grid-debug-width} - (#{$grid-gap} / #{$grid-items}))
        );

        content: '';

        @include media-query-min($breakpoint-large-lbound) {
            max-width: $breakpoint-large-lbound;
        }

        @include media-query-min($breakpoint-xlarge-lbound) {
            max-width: $breakpoint-xlarge-lbound;
        }
    }
}

.landing-section__content {
    max-width: 100%;
    margin: 0 auto;

    @include media-query-min($breakpoint-large-lbound) {
        max-width: $breakpoint-large-lbound;
    }

    @include media-query-min($breakpoint-xlarge-lbound) {
        max-width: $breakpoint-xlarge-lbound;
    }

    &--with-padding {
        padding: $spacing-xl $spacing-m;

        @include media-query-min($breakpoint-medium-lbound) {
            padding: rem-calc(72) $spacing-m;
        }
    }
}
