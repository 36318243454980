@import '../../styles/utils';

.hero-search-card__label {
    margin-bottom: $spacing-xs;
}

.hero-search-card__label-text {
    font-weight: $font-weight-medium;
}

.hero-search-card__search {
    @include media-query-max($breakpoint-small-ubound) {
        flex-direction: column;
    }
}

.hero-search-card__button {
    align-self: start;
    margin-top: $spacing-m;

    @include media-query-min($breakpoint-medium-lbound) {
        align-self: initial;
        margin-top: 0;
        margin-left: $spacing-m;
    }
}

.hero-search-card__button--error {
    margin-top: $spacing-xl;
    @include media-query-min($breakpoint-medium-lbound) {
        margin-top: 0;
    }
}
