@import '../../styles/utils';

.hero-card {
    margin: -$spacing-m;
    padding: $spacing-m !important;

    border-radius: 0 !important;

    @include media-query-min($breakpoint-medium-lbound) {
        margin: 0;
        padding: $spacing-xl !important;

        border-radius: 8px !important;
    }
}
